import {
  Box,
  Button,
  HStack,
  Text,
  Tooltip,
  useColorModeValue as mode,
  useDisclosure,
} from '@chakra-ui/react';
import { useMemo, memo } from 'react';
import type { ReactNode } from 'react';
import { useForm } from 'react-hook-form';
import { FilterLabel, SelectedTag, WmsModal, WmsModalFooter } from '@components';
import { RHFInput } from '@components/forms';
import { yupResolver } from '@hookform/resolvers/yup';
import type { InferType } from 'yup';
import { filterOrderByDateRange } from '@schemas';
import { useLocalDateFormatter } from '@hooks';
import { addDays } from 'date-fns';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendar } from '@fortawesome/pro-regular-svg-icons';
import { isDateRangeOptionSelected } from '@utils/filterSelection';
import { useHighlightSelectedProps } from '@hooks/useHighlightSelectedProps';

export function getDateRangeFilterString(startDateString?: string, endDateString?: string) {
  if (startDateString && endDateString) {
    return `${startDateString} - ${endDateString}`;
  }

  if (startDateString) {
    return `From ${startDateString}`;
  }

  if (endDateString) {
    return `Until ${endDateString}`;
  }

  return '';
}

export type DateFilterData = InferType<typeof filterOrderByDateRange>;

export const DateRangeFilter = memo(
  ({
    buttonLabel,
    label,
    onClearValue,
    onSubmit,
    selectedDataLabel,
    value,
    submitDateString,
    minDate,
    maxDate,
  }: {
    buttonLabel: ReactNode;
    label?: ReactNode;
    onClearValue: () => void;
    onSubmit: (data: DateFilterData) => void;
    selectedDataLabel?: ReactNode;
    submitDateString?: boolean; // submit a date string instead of datetime
    value?: DateFilterData;
    minDate?: string;
    maxDate?: string;
  }) => {
    const { onOpen, onClose, isOpen } = useDisclosure();
    const { formatDate } = useLocalDateFormatter();
    const showSelectedTag = value?.startDate || value?.endDate;
    const selectedDates = useMemo(() => {
      const startDateString = value?.startDate && formatDate(value.startDate);
      const endDateString = value?.endDate && formatDate(value.endDate);

      return getDateRangeFilterString(startDateString, endDateString);
    }, [formatDate, value?.startDate, value?.endDate]);

    const {
      handleSubmit,
      formState: { isSubmitting },
      control,
      reset,
      watch,
    } = useForm({
      resolver: yupResolver(filterOrderByDateRange),
      defaultValues: {
        startDate: value?.startDate ?? '',
        endDate: value?.endDate ?? '',
      },
    });

    const startDate = watch('startDate');
    const endDate = watch('endDate');

    const handleFormSubmit = handleSubmit(({ startDate, endDate }) => {
      const endDateString = submitDateString
        ? endDate
        : endDate && addDays(new Date(endDate), 1).toISOString();

      onSubmit({
        startDate,
        endDate: endDateString,
      });
      onClose();
    });

    const handleCancel = () => {
      onClose();
      reset({ startDate: '', endDate: '' });
    };

    const calendarPickerFilter = mode(undefined, 'invert(1)');

    const rhfInputCss = useMemo(() => {
      return {
        '&::-webkit-calendar-picker-indicator': {
          element: calendarPickerFilter,
        },
      };
    }, [calendarPickerFilter]);

    const shouldHighlight = useMemo(() => isDateRangeOptionSelected(value), [value]);
    const highlightingProps = useHighlightSelectedProps(shouldHighlight);

    return (
      <Box>
        {label && <FilterLabel label={label} />}
        {showSelectedTag ? (
          <SelectedTag
            borderRadius="3xl"
            fontWeight="normal"
            fontSize="md"
            h="40px"
            label={`${selectedDataLabel}${selectedDates}`}
            onClickClose={() => {
              onClearValue();
              reset({ startDate: '', endDate: '' });
            }}
            px={4}
            justifyContent="space-between"
            w="full"
            {...highlightingProps}
          />
        ) : (
          <HStack>
            <Button
              variant="filter"
              onClick={onOpen}
              w="full"
              display="inline"
              textAlign="left"
              pr={2.5}
            >
              <HStack justifyContent="space-between">
                <Text as="span" color="inherit">
                  {buttonLabel}
                </Text>
                <FontAwesomeIcon icon={faCalendar} />
              </HStack>
            </Button>
            <WmsModal isOpen={isOpen} onClose={handleCancel} title="Filter by Date">
              <form onSubmit={handleFormSubmit}>
                <HStack alignItems="flex-end" mb={2}>
                  <RHFInput
                    name="startDate"
                    label="From"
                    type="date"
                    control={control}
                    css={rhfInputCss}
                    min={minDate ?? ''}
                    max={maxDate ?? ''}
                  />
                  <RHFInput
                    name="endDate"
                    label="Until"
                    type="date"
                    control={control}
                    css={rhfInputCss}
                    min={minDate ?? ''}
                    max={maxDate ?? ''}
                  />
                </HStack>
                <WmsModalFooter>
                  <Button variant="outline" colorScheme="gray" onClick={handleCancel}>
                    Cancel
                  </Button>
                  <Tooltip
                    isDisabled={!!startDate || !!endDate}
                    hasArrow
                    label="Select a from and/or until date"
                    shouldWrapChildren
                  >
                    <Button
                      variant="primary"
                      isDisabled={!startDate && !endDate}
                      type="submit"
                      isLoading={isSubmitting}
                    >
                      Save
                    </Button>
                  </Tooltip>
                </WmsModalFooter>
              </form>
            </WmsModal>
          </HStack>
        )}
      </Box>
    );
  }
);
