import { Button, Flex, Text } from '@chakra-ui/react';
import { WmsModal } from '@components/WmsModal';
import { yupResolver } from '@hookform/resolvers/yup';
import type {
  BarcodeDetails,
  BarcodeDetailsCombined,
  LicensePlate,
  Location,
  OutboundOrder,
  Product,
  Shipper,
} from '@store/services/api.generated';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { RHFInput } from '@components/forms';
import { useLazyGetBarcodeLookupQuery } from '@store/services/api';
import { Loading } from '@components/Loading';
import { LicensePlatePreview } from './LicensePlatePreview';
import { LocationPreview } from './LocationPreview';
import { ProductPreview } from './ProductPreview';
import { OutboundPreview } from './OutboundPreview';
import { useLocalStorageList } from '@features/scanner/quick-scan';
import { PreviousScanResult } from './components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHistory } from '@fortawesome/pro-regular-svg-icons';
import type { ScanResultType } from './types';
import { useGetShipperOptions } from '@hooks';

interface BarcodeBuddyProps {
  isOpen: boolean;
  onClose: () => void;
  onOpen: () => void;
}

type BarcodeDetailsKeys = keyof BarcodeDetails;

const barcodeSearchSchema = yup.object({
  barcode: yup.string().required('I need a barcode to look up!'),
});

type FormData = yup.InferType<typeof barcodeSearchSchema>;

const resultTypeToKey: Record<ScanResultType, BarcodeDetailsKeys> = {
  lpn: 'license_plates',
  product: 'products',
  outbound: 'outbounds',
  location: 'locations',
};
const schemaToScanResultType: Record<
  'location' | 'product' | 'outbound' | 'license_plate',
  ScanResultType
> = {
  location: 'location',
  product: 'product',
  outbound: 'outbound',
  license_plate: 'lpn',
};
type ResultType = Location | LicensePlate | Product | OutboundOrder;

const getResultAsRecentScan = (
  appType: ScanResultType,
  result: ResultType,
  shipperMap: Map<string, Shipper>
) => {
  let link = '';
  let value = '';
  let entity;

  switch (appType) {
    case 'lpn':
      value = (result as LicensePlate).number ?? '';
      link = `/license-plates/${encodeURIComponent(value)}`;
      break;
    case 'location':
      value = (result as Location).name ?? '';
      link = `/locations/${encodeURI(value)}`;
      break;
    case 'product':
      entity = result as Product;
      value = entity.name ?? '';
      link = `/items/${encodeURIComponent(
        shipperMap.get(entity.shipper_id ?? '')?.name ?? ''
      )}/${encodeURIComponent(entity.sku ?? '')}`;
      break;
    case 'outbound':
      entity = result as OutboundOrder;
      value = entity.external_id ?? '';
      link = `/outbounds/${encodeURIComponent(
        shipperMap.get(entity.shipper_id ?? '')?.name ?? ''
      )}/${encodeURIComponent(value)}`;
      break;
  }

  return {
    link,
    type: appType as ScanResultType,
    value,
  };
};

const addCombinedResultItemToRecentScansList = (
  result: BarcodeDetailsCombined,
  shipperMap: Map<string, Shipper>,
  addRecentScan: (value: { value: string; link: string; type: ScanResultType }) => void
) => {
  const schema = result?.schema!;
  const appType = schemaToScanResultType[schema];
  const { link, type, value } = getResultAsRecentScan(appType, result.data!, shipperMap);
  addRecentScan({
    link,
    type,
    value,
  });
};

const addResultItemToRecentScansList = (
  result: ResultType,
  appType: ScanResultType,
  shipperMap: Map<string, Shipper>,
  addRecentScan: (value: { value: string; link: string; type: ScanResultType }) => void
) => {
  const { link, type, value } = getResultAsRecentScan(appType, result, shipperMap);
  addRecentScan({
    link,
    type,
    value,
  });
};

const ResultListing = ({
  results,
  shipperMap,
  closeAndReset,
  addRecentScan,
}: {
  results: BarcodeDetails;
  shipperMap: Map<string, Shipper>;
  closeAndReset: () => void;
  addRecentScan: (value: { value: string; link: string; type: ScanResultType }) => void;
}) => {
  if (Array.isArray(results?.combined) && results.combined.length > 0) {
    return (
      <>
        {results.combined.map((result) => {
          const data = result?.data!;
          switch (result.schema) {
            case 'product':
              return (
                <ProductPreview
                  key={(data as Product).id}
                  product={data as Product}
                  shipper={shipperMap.get((data as Product).shipper_id ?? '')}
                  closeModal={closeAndReset}
                  handleClick={() =>
                    addCombinedResultItemToRecentScansList(result, shipperMap, addRecentScan)
                  }
                />
              );
            case 'outbound':
              return (
                <OutboundPreview
                  key={(data as OutboundOrder).id}
                  closeModal={closeAndReset}
                  order={data as OutboundOrder}
                  shipper={shipperMap.get((data as OutboundOrder).shipper_id ?? '')}
                  handleClick={() =>
                    addCombinedResultItemToRecentScansList(result, shipperMap, addRecentScan)
                  }
                />
              );
            case 'license_plate':
              return (
                <LicensePlatePreview
                  key={(data as LicensePlate).id}
                  licensePlate={data as LicensePlate}
                  shipperMap={shipperMap}
                  closeModal={closeAndReset}
                  handleClick={() =>
                    addCombinedResultItemToRecentScansList(result, shipperMap, addRecentScan)
                  }
                />
              );
            case 'location':
              return (
                <LocationPreview
                  key={(data as Location).id}
                  location={data as Location}
                  shipperMap={shipperMap}
                  closeModal={closeAndReset}
                  handleClick={() =>
                    addCombinedResultItemToRecentScansList(result, shipperMap, addRecentScan)
                  }
                />
              );
          }
        })}
      </>
    );
  }
  return (
    <>
      {(results.products ?? []).map((product) => (
        <ProductPreview
          key={product.id}
          product={product}
          shipper={shipperMap.get(product.shipper_id ?? '')}
          closeModal={closeAndReset}
        />
      ))}
      {(results.outbounds ?? []).map((outbound) => (
        <OutboundPreview
          key={outbound.id}
          closeModal={closeAndReset}
          order={outbound}
          shipper={shipperMap.get(outbound.shipper_id ?? '')}
        />
      ))}
      {(results.license_plates ?? []).map((licensePlate) => (
        <LicensePlatePreview
          key={licensePlate.id}
          licensePlate={licensePlate}
          shipperMap={shipperMap}
          closeModal={closeAndReset}
        />
      ))}
      {(results.locations ?? []).map((location) => (
        <LocationPreview
          key={location.id}
          location={location}
          shipperMap={shipperMap}
          closeModal={closeAndReset}
        />
      ))}
    </>
  );
};

export function BarcodeBuddy({ isOpen, onClose, onOpen }: BarcodeBuddyProps) {
  const { addItem: addRecentScan, list: recentScans } = useLocalStorageList<{
    value: string;
    link: string;
    type: ScanResultType;
  }>('recentScan', { limit: 5 });

  const initialState = useMemo(
    () => ({
      license_plates: [],
      locations: [],
      outbounds: [],
      products: [],
    }),
    []
  );

  const [results, setResults] = useState<BarcodeDetails>(initialState);

  const [fetchBarcodeResults] = useLazyGetBarcodeLookupQuery();

  const { shipperMap, isLoading: isShippersLoading } = useGetShipperOptions({});

  const handleKeyDown = useMemo(() => {
    return (e: KeyboardEvent) => {
      if ((e.ctrlKey || e.metaKey) && e.key === 'j') {
        if (!isOpen) {
          onOpen();
        } else {
          onClose();
        }
      }
    };
  }, [isOpen, onOpen, onClose]);

  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [handleKeyDown]);

  const {
    handleSubmit,
    formState: { isSubmitting },
    control,
    setError,
    clearErrors,
    resetField,
  } = useForm<FormData>({
    resolver: yupResolver(barcodeSearchSchema),
  });

  const addResultsToRecentScansList = (results: BarcodeDetails) => {
    if (Array.isArray(results?.combined) && results.combined.length > 0) {
      return;
    }
    for (const [appType, responseType] of Object.entries(resultTypeToKey)) {
      results[responseType]?.forEach((result) => {
        addResultItemToRecentScansList(
          result as ResultType,
          appType as ScanResultType,
          shipperMap,
          addRecentScan
        );
      });
    }
  };

  const searchForBarcode = handleSubmit(async ({ barcode }) => {
    try {
      setResults(initialState);

      const searchResults = await fetchBarcodeResults({ barcode }).unwrap();

      const hasValidResults = Object.keys(searchResults?.data ?? {}).some(
        (key) => (searchResults?.data?.[key as BarcodeDetailsKeys]?.length ?? 0) > 0
      );

      if (searchResults?.data && hasValidResults) {
        setResults(searchResults.data);

        addResultsToRecentScansList(searchResults.data);
      } else {
        setError('barcode', { message: 'No results found' });
      }
    } catch (err) {
      setError('barcode', { message: 'Barcode lookup failed 😞' });
    }
  });

  const isLoading = isShippersLoading || isSubmitting;

  const hasResults = useMemo(
    () => JSON.stringify(results) !== JSON.stringify(initialState),
    [initialState, results]
  );

  const closeAndReset = useCallback(() => {
    setResults(initialState);
    resetField('barcode');
    clearErrors();
    onClose();
  }, [clearErrors, initialState, onClose, resetField]);

  return (
    <WmsModal isOpen={isOpen} title="Barcode Lookup" onClose={closeAndReset}>
      <Flex as="form" gridGap={3} mb={1} onSubmit={searchForBarcode} alignItems="baseline">
        <RHFInput
          autoFocus
          hideLabel
          label="Barcode"
          control={control}
          name="barcode"
          placeholder="Scan or Enter Any Barcode"
        />

        <Button type="submit" variant="ghost" colorScheme="blue" isLoading={isSubmitting}>
          Search
        </Button>
      </Flex>

      <Loading isLoading={isLoading} h={36}>
        {hasResults ? (
          <Flex direction="column" my={3} gridGap={5} pb={2}>
            <ResultListing
              results={results}
              shipperMap={shipperMap}
              closeAndReset={closeAndReset}
              addRecentScan={addRecentScan}
            />
          </Flex>
        ) : recentScans?.length > 0 ? (
          <Flex direction="column" my={3} gridGap={3}>
            <Text fontWeight="semibold" fontSize="sm">
              <FontAwesomeIcon fixedWidth size="sm" icon={faHistory} /> History
            </Text>
            {recentScans?.map((scan) => (
              <PreviousScanResult
                key={`${scan.type}:${scan.value}`}
                type={scan.type}
                link={scan.link}
                name={scan.value}
                closeModal={closeAndReset}
              />
            ))}
          </Flex>
        ) : null}
      </Loading>
    </WmsModal>
  );
}
