import * as yup from 'yup';

export const buildingAddressSchema = yup.object({
  name: yup.string(),
  line1: yup.string().required('Address is required'),
  line2: yup.string(),
  city: yup.string().required('City is required'),
  state: yup.string().required('State is required'),
  country: yup.string().required('Country is required'),
  postal_code: yup.string().required('Zip/postal is required'),
  timezone: yup.string().required('Timezone is required'),
  insights_enabled: yup.boolean(),
});
