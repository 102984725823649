import type { FormControlProps } from '@chakra-ui/react';
import {
  FormControl,
  FormLabel,
  HStack,
  Input,
  InputGroup,
  InputLeftElement,
  Select,
  useColorModeValue as mode,
} from '@chakra-ui/react';
import { faMagnifyingGlass } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { usePrevious } from '@hooks';
import { useHighlightSelectedProps } from '@hooks/useHighlightSelectedProps';
import { isStringOptionSelected } from '@utils/filterSelection';
import { useEffect, useMemo, useRef, useState } from 'react';
import { FilterLabel } from './FilterLabel';

interface SearchFieldProps extends FormControlProps {
  placeholder?: string;
  defaultSearch?: SearchType;
  searchValue?: string;
  onInputChange: (value: string) => void;
  debounce?: boolean;
  hideTypeSelect?: boolean;
}

type SearchType = 'startsWith' | 'endsWith' | 'contains';

const searchPatterns: Record<SearchType, string> = {
  startsWith: '{term}%',
  endsWith: '%{term}',
  contains: '%{term}%',
};

const DEBOUNCE_MS = 500;

const buildWithSearchType = (input: string, type: SearchType) => {
  return input.length === 0
    ? ''
    : searchPatterns[type].replace('{term}', input.replaceAll('%', ''));
};

const trimSearchType = (input?: string) => {
  return input?.replaceAll('%', '');
};

const getSearchTypeFromParams = (input?: string): SearchType | undefined | null => {
  const isContains = input?.match(/^%.*%$/) && 'contains';
  const isStartsWith = input?.match(/%$/) && 'startsWith';
  const isEndsWith = input?.match(/^%/) && 'endsWith';

  return isContains ?? isStartsWith ?? isEndsWith;
};

export const SearchFieldWithTypeSelector = ({
  placeholder = 'Search',
  defaultSearch = 'contains',
  debounce = false,
  searchValue,
  onInputChange,
  hideTypeSelect = false,
  label,
  ...rest
}: SearchFieldProps) => {
  const defaultSearchType = getSearchTypeFromParams(searchValue) ?? defaultSearch;

  const [searchType, setSearchType] = useState<SearchType>(defaultSearchType);
  const [inputValue, setInputValue] = useState(searchValue);
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    // when the value of the parent changes, it should overwrite
    // the current value (e.g. back button) otherwise, this value
    // will just be updated by the `handleInputChanged` handler
    setInputValue(searchValue);
  }, [searchValue]);

  const prevSearchTerm = usePrevious(searchValue);
  const prevSearchType = usePrevious(searchType);

  const sendInputChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
    const searchTerm = event.target.value;
    const canFireChange =
      prevSearchTerm === undefined
        ? searchTerm !== undefined
        : prevSearchTerm !== searchTerm || prevSearchType !== searchType;

    if (canFireChange) {
      onInputChange(buildWithSearchType(searchTerm, searchType));
    }
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.target.value);

    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }

    if (!debounce) {
      sendInputChanged(event);
      return;
    }

    timeoutRef.current = setTimeout(() => {
      sendInputChanged(event);
    }, DEBOUNCE_MS);
  };

  useEffect(() => {
    return () => {
      if (timeoutRef.current) clearTimeout(timeoutRef.current);
    };
  }, []);

  const handleSearchTypeChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const newSearchType = event.target.value as SearchType;
    setSearchType(newSearchType);

    onInputChange(buildWithSearchType(searchValue ?? '', newSearchType));
  };

  useEffect(() => {
    const updatedSearchType = getSearchTypeFromParams(searchValue);

    if (updatedSearchType && updatedSearchType !== searchType) {
      setSearchType(updatedSearchType);
    }
  }, [searchType, searchValue]);

  const shouldHighlight = useMemo(() => isStringOptionSelected(searchValue), [searchValue]);
  const highlightingProps = useHighlightSelectedProps(shouldHighlight);

  return (
    <FormControl id="search" {...rest}>
      {label && <FilterLabel label={label} />}
      <HStack
        borderWidth="1px"
        height={10}
        bg={mode('white', 'darkmode.gray.800')}
        borderRadius="4px"
        borderColor={mode('gray.200', 'whiteAlpha.300')}
        outline="2px solid transparent"
        outlineOffset="-1px"
        _focusWithin={{
          outlineColor: mode('blue.500', 'blue.300'),
        }}
        alignItems="center"
        {...highlightingProps}
      >
        <InputGroup borderColor={mode('gray.200', 'whiteAlpha.300')}>
          <FormLabel srOnly>{placeholder}</FormLabel>
          <InputLeftElement pointerEvents="none" h={8} color="gray.400">
            <FontAwesomeIcon icon={faMagnifyingGlass} />
          </InputLeftElement>
          <Input
            borderWidth="0px"
            rounded="base"
            type="search"
            w={275}
            h={8}
            flexGrow={1}
            placeholder={placeholder}
            value={trimSearchType(inputValue)}
            onChange={handleInputChange}
            borderTopEndRadius={hideTypeSelect ? '4px' : 0}
            borderBottomEndRadius={hideTypeSelect ? '4px' : 0}
            _focus={{ outline: 'none', boxShadow: 'none' }}
          />
        </InputGroup>
        {!hideTypeSelect && (
          <Select
            variant="unstyled"
            value={searchType}
            onChange={handleSearchTypeChange}
            w="144px"
            rootProps={{
              style: { marginInlineStart: '-1px' },
            }}
            flexShrink={0}
          >
            <option value="startsWith">Starts With</option>
            <option value="contains">Contains</option>
            <option value="endsWith">Ends With</option>
          </Select>
        )}
      </HStack>
    </FormControl>
  );
};
