import { useSetFieldErrors, useToast } from '@hooks';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { usePostBuildingsMutation, usePostShippersMutation } from '@store/services/api';
import { FormButtons, FormContentHeader } from '@components/multi-step-form';
import { BuildingAddressFormFields } from '../shared';
import type { MultiStepFormPageProps } from '@components/multi-step-form';
import { CreateBuildingSuccess } from '@features/building/CreateBuilding/pages';
import type { FormEventHandler } from 'react';
import { useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { buildingAddressSchema } from '@schemas';
import type { CreateBuildingFormState } from '@features/building/CreateBuilding/CreateBuilding';
import { updateBuildingIdInLocalStorage } from '@utils';

export const BuildingAddress = ({
  multiStepFormState,
  onBackClick,
  onClose,
  onSkipClick,
  onSuccess,
}: MultiStepFormPageProps<CreateBuildingFormState>) => {
  const { handleSubmit, control, setError } = useForm({
    defaultValues: {
      line1: '',
      line2: '',
      city: '',
      state: '',
      country: 'US',
      postal_code: '',
      timezone: 'America/New_York',
    },
    resolver: yupResolver(buildingAddressSchema),
  });

  const [isBuildingCreated, setIsBuildingCreated] = useState(false);
  const [shipperId, setShipperId] = useState<string | undefined>();
  const [createBuilding] = usePostBuildingsMutation();
  const [createShipper] = usePostShippersMutation();
  const { errorToast, successToast } = useToast();
  const setFieldErrors = useSetFieldErrors({ setError });
  const [, setSearchParams] = useSearchParams();

  const onSubmit = handleSubmit(async (building) => {
    try {
      const newBuilding = await createBuilding({
        body: {
          name: multiStepFormState?.buildingName ?? '',
          provider_id: multiStepFormState?.providerId ?? '',
          timezone: building.timezone,
          shipping_from: {
            line1: building.line1,
            line2: building.line2,
            city: building.city,
            state: building.state,
            country: building.country,
            postal_code: building.postal_code,
          },
        },
      }).unwrap();

      const newBuildingId = newBuilding?.data?.id;

      // creating new building will also switch to that building (via authSlice reducer). This just fixes the search params/local storage
      if (newBuildingId) {
        updateBuildingIdInLocalStorage(newBuildingId);
        setSearchParams({ building_id: newBuildingId }, { replace: true });
      }
    } catch (err) {
      const didSetErrors = setFieldErrors(err);
      if (!didSetErrors) {
        errorToast('An error occurred trying to create building');
      }

      return;
    }

    if (multiStepFormState?.shipperName) {
      try {
        const newShipper = await createShipper({
          shipper: {
            name: multiStepFormState?.shipperName,
          },
        }).unwrap();

        setShipperId(newShipper?.data?.id);
      } catch (err) {
        errorToast('An error occurred trying to create shipper');
      }
    }

    setIsBuildingCreated(true);
    successToast('Building created successfully.');
  });

  const onNextClick: FormEventHandler = async (e) => {
    e.preventDefault();

    if (isBuildingCreated && !!shipperId) {
      onSuccess({ ...multiStepFormState, shipperId });
      return;
    }

    if (isBuildingCreated) {
      // Skip units of measure and proceed to Locations uploads
      onSkipClick(3);
      return;
    }

    await onSubmit();
  };

  const submitButtonText = isBuildingCreated
    ? shipperId
      ? 'Continue to units of measure'
      : 'Continue to location uploads'
    : 'Create';

  return (
    <form onSubmit={onNextClick}>
      {!isBuildingCreated && (
        <>
          <FormContentHeader
            mainText="Tell us where your building is located"
            secondaryText="We'll get your building created now, and you can add locations, items
          and lpns afterward (if you have them ready)."
            stepNumber={2}
            totalSteps={6}
          />
          <BuildingAddressFormFields autoFocus formControl={control} />
        </>
      )}
      {isBuildingCreated && <CreateBuildingSuccess isShipperCreated={!!shipperId} />}
      <FormButtons
        allowBack={!isBuildingCreated}
        allowSkip={isBuildingCreated}
        onBackClick={onBackClick}
        submitLabel={submitButtonText}
        onSkipClick={onClose}
        skipLabel="Later"
      />
    </form>
  );
};

export default BuildingAddress;
