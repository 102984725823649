import type { SortingRule } from 'react-table';

export function sortingToParams(sortBy: SortingRule<Record<string, any>>[]) {
  return sortBy.map(({ id, desc }) => `${id}:${desc ? 'desc' : 'asc'}`).join();
}

export function sortingFromParams(sorting: string) {
  return sorting.split(',').map((sort) => {
    const [id, direction] = sort.split(':');
    return { id, desc: direction === 'desc' };
  });
}

export type CompareDirection = 'asc' | 'desc';
export type SortComparator<T> = (a: T | null, b: T | null, direction?: CompareDirection) => number;
/**
 * Should be used with array sort ex. Array.sort((a,b) => alphaSortCompare(a,b, 'desc'))
 *
 * @param {string} a A string to compare to the second parameter
 * @param {string} b A string to compare to the first parameter
 * @param {'asc'|'desc'} direction  Defaults to `asc`. Accepts `asc` or `desc`
 */
export const alphaSortCompare: SortComparator<string> = (a, b, direction = 'asc') => {
  const aVal = a?.toUpperCase() ?? '';
  const bVal = b?.toUpperCase() ?? '';

  return direction === 'asc' ? aVal.localeCompare(bVal) : bVal.localeCompare(aVal);
};

/**
 * Should be used with array sort ex. Array.sort((a,b) => numberCompareSort(a,b, 'desc'))
 * Will consider null values as the lowest possible value
 *
 * @param {number|null} a A number to compare to the second parameter
 * @param {number|null} b A number to compare to the first parameter
 * @param direction Defaults to `asc`.
 */
export const numberSortCompare: SortComparator<number> = (a, b, direction = 'asc') => {
  const aVal = a ?? Number.NEGATIVE_INFINITY;
  const bVal = b ?? Number.NEGATIVE_INFINITY;

  return direction === 'asc' ? aVal - bVal : bVal - aVal;
};

// Sometimes you've gotta channel your inner andrew w. k. and PARTY HARD
export type ExtractIdStrings<T> =
  T extends ReadonlyArray<infer U> ? (U extends Record<'id', infer V> ? V : U) : T;

export type GetSortersFromColumns<Columns extends ReadonlyArray<any> = ReadonlyArray<any>> =
  `${ExtractIdStrings<Columns>}:${'desc' | 'asc'}`;

export type ExtractSort<Sort extends string, Columns> = Sort extends `${infer K}:${infer R}`
  ? K extends ExtractIdStrings<Columns>
    ? [K, R]
    : never
  : never;

export const extractSortParts = <S extends string, C>(sort: S, columns: C) => {
  const [key, direction] = sort.split(':');
  return [key, direction] as ExtractSort<S, C>;
};
