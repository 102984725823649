import { Fragment, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useAuth, useToast } from '@hooks';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Box,
  Button,
  Grid,
  GridItem,
  Heading,
  Stack,
  useColorModeValue as mode,
  useDisclosure,
} from '@chakra-ui/react';
import { useDeleteUomByUomIdMutation, usePostUomMutation } from '@store/services/api';
import { createUnitOfMeasureSchema } from '@schemas';
import { InlineXButton } from '@components';
import { AddUomForm } from './AddUomForm';
import { DeleteUomModal } from './DeleteUomModal';
import type { StackProps } from '@chakra-ui/react';
import type { UnitOfMeasure } from '@store/services/api.generated';

type FormData = {
  name: string;
  abbreviation: string;
};

interface UnitsOfMeasureEditorProps extends StackProps {
  shipperId?: string;
  unitsOfMeasure?: UnitOfMeasure[];
}

export const UnitsOfMeasureEditor = ({
  shipperId,
  unitsOfMeasure = [],
  ...props
}: UnitsOfMeasureEditorProps) => {
  const { isAuthorized } = useAuth();
  const [isAddingUom, setIsAddingUom] = useState(false);
  const [selectedUom, setSelectedUom] = useState<UnitOfMeasure | undefined>();

  const [createUom] = usePostUomMutation();
  const [deleteUom] = useDeleteUomByUomIdMutation();

  const { isOpen, onClose, onOpen } = useDisclosure();
  const { errorToast, successToast } = useToast();

  const {
    control,
    formState: { isSubmitting },
    handleSubmit,
    reset,
  } = useForm<FormData>({
    resolver: yupResolver(createUnitOfMeasureSchema),
  });

  useEffect(() => {
    if (!isAddingUom) {
      // Reset the form when the user hides the "add" form
      reset();
    }
  }, [isAddingUom, reset]);

  const confirmDeleteUom = (uom: UnitOfMeasure) => {
    setSelectedUom(uom);
    onOpen();
  };

  const resetDeleteUom = () => {
    setSelectedUom(undefined);
    onClose();
  };

  const handleDeleteUom = async () => {
    try {
      await deleteUom({
        uomId: selectedUom?.id!,
      }).unwrap();

      successToast('Deleted');
    } catch (e) {
      console.error(e);
      errorToast('There was a problem deleting the unit of measure');
    }

    resetDeleteUom();
  };

  const handleAddUom = handleSubmit(async (uom: UnitOfMeasure) => {
    try {
      await createUom({
        unitOfMeasure: {
          ...uom,
          shipper_id: shipperId,
        },
      }).unwrap();

      successToast('Updated');
      setIsAddingUom(false);
    } catch (e) {
      if (process.env.NODE_ENV !== 'test') console.error(e);
      errorToast('There was a problem adding the unit of measure');
    }
  });

  return (
    <Stack {...props} align="flex-start">
      <Grid templateColumns="repeat(3, 8rem)" gap={2} mb={2} color={mode('black', 'white')}>
        <Heading as="h3" size="xs" textTransform="uppercase" color="gray.500" pb={2}>
          Name
        </Heading>
        <Heading as="h3" size="xs" textTransform="uppercase" color="gray.500" pb={2}>
          Abbreviation
        </Heading>
        <Box />
        {unitsOfMeasure.map((uom, index) => (
          <Fragment key={uom.id}>
            <Box>{uom.name}</Box>
            <Box>{uom.abbreviation}</Box>
            <Box>
              {isAuthorized('admin') && index > 0 && (
                <InlineXButton testId={`delete-${uom.id}`} onClick={() => confirmDeleteUom(uom)} />
              )}
            </Box>
          </Fragment>
        ))}
        {isAddingUom && (
          <GridItem colSpan={3}>
            <AddUomForm
              formControl={control}
              isSubmitting={isSubmitting}
              onCancel={() => setIsAddingUom(false)}
              onSubmit={handleAddUom}
            />
          </GridItem>
        )}
      </Grid>
      {isAuthorized('admin') && !isAddingUom && (
        <Button
          variant="link"
          fontSize="sm"
          _hover={{ textDecoration: 'none' }}
          onClick={() => setIsAddingUom(true)}
        >
          + add unit of measure
        </Button>
      )}

      <DeleteUomModal
        isOpen={isOpen}
        onClose={resetDeleteUom}
        onConfirm={handleDeleteUom}
        uom={selectedUom}
      />
    </Stack>
  );
};
