import * as yup from 'yup';

export const filterOrderByDateRange = yup.object({
  startDate: yup.string(),
  endDate: yup.string(),
});

export const updateInboundOrderExpectedDateSchema = yup.object({
  expected_at_date: yup.string().required('Expected at date is required'),
  expected_at_time: yup.string(),
  status: yup
    .string()
    .oneOf(['open', 'arrived', 'partially_received', 'received', 'put_away', 'canceled'])
    .default(undefined),
});

export const findOrderSchema = yup.object({
  orderNumber: yup.string().required('Purchase order # is required'),
});
