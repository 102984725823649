import * as yup from 'yup';

/**
 * Minimum password requirements:
 * One lowercase letter
 * One uppercase letter
 * One digit
 * One special character (!@#$%^&*)
 * At least 8 characters
 */
const passwordRegex = /^(?=.*\d)(?=.*[A-Z])(?=.*[a-z])(?=.*[!@#$%^&*])([^\s]){8,}$/g;

const passwordSchema = yup.string().matches(passwordRegex, {
  message: 'password does not meet requirements',
  excludeEmptyString: true,
});

const confirmPasswordSchema = yup
  .string()
  .oneOf([yup.ref('password')], 'Passwords must match')
  .default(null);

const base = yup.object().shape({
  role: yup.string(),
  username: yup.string(),
  email: yup.string().email(),
  name: yup.string(),
  password: passwordSchema,
  confirmPassword: confirmPasswordSchema,
});

const create = base.shape({
  role: yup.string().required(),
  username: yup.string().required(),
  email: yup.string().email().required(),
  name: yup.string().required(),
  password: passwordSchema.required(),
  confirmPassword: confirmPasswordSchema.required('Please enter the new password again'),
});

export { create as createUserSchema, base as updateUserSchema };
