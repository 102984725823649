import * as yup from 'yup';

export const createSpecialInstructionSchema = yup.object().shape({
  name: yup.string().required(),
  description: yup.string().required().max(250),
});

export const insertSpecialInstructionSchema = yup.object().shape({
  insert_special_instruction: yup.string().required(),
});
