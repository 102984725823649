import type { TableProps, BoxProps } from '@chakra-ui/react';
import {
  Skeleton,
  Table,
  Text,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  Button,
  HStack,
  Box,
  Heading,
  Flex,
  useColorModeValue as mode,
} from '@chakra-ui/react';
import type { Pagination } from '@store/services/api.generated';
import type { ReactNode } from 'react';
import { useCallback } from 'react';
import { useEffect, useMemo } from 'react';
import { useTable, usePagination, useColumnOrder, useSortBy } from 'react-table';
import type { ColumnInstance, CellProps } from 'react-table';
import { FaArrowRight, FaArrowLeft, FaArrowUp, FaArrowDown } from 'react-icons/fa';
import { scrollToPageHeader, sortingFromParams, sortingToParams } from '@utils';
import { DropdownFilter, GenericError } from '@components';
import { usePrevious } from '@hooks';
import { NoTranslate } from './NoTranslate';

// TODO: Fix types
export interface TableColumn<DataType extends Record<string, unknown> = Record<string, unknown>> {
  Header: ColumnInstance<DataType>['Header'];
  accessor: string | ((record: DataType) => string);
  hide?: boolean;
  id?: string;
  Cell?: (props: CellProps<DataType>['cell'] & DataType) => ReactNode; // TODO: This is silly. Need to look this up.
  disableSortBy?: boolean;
  isLoading?: boolean;
  isError?: boolean;
}

interface BaseTableProps<Sorters> extends Omit<TableProps, 'title'> {
  data?: any[];
  title?: React.ReactNode;
  subHeader?: React.ReactNode;
  columns: ReadonlyArray<TableColumn<any>> | TableColumn<any>[];
  emptyTableElement?: React.ReactNode;
  tableContainerProps?: BoxProps;
  tableHeaderProps?: BoxProps;
  tableRowProps?: BoxProps;
  customRowRenderer?: (data: any) => React.ReactNode;
  columnOrder?: string[];
  defaultSort?: string;
  hideRowCounts?: boolean;
  error?: unknown;
  isError?: boolean;
  isLoading?: boolean;
  isSortable?: boolean;
  onSortChange?: (sortBy: Sorters) => void;
  setHoveredRow?: (index: number | null) => void;
}

type PaginationProps =
  | {
      customPageComponent?: undefined;
      pageInfo: Pagination;
      onPageChange: (page: Partial<Pagination>) => void;
    }
  | {
      customPageComponent?: undefined;
      pageInfo?: undefined;
      onPageChange?: undefined;
    }
  | {
      customPageComponent: React.ReactNode;
      pageInfo?: undefined;
      onPageChange?: undefined;
    };

export type WMSTableProps<Sorters = any> = BaseTableProps<Sorters> & PaginationProps;

const pageSizes = [20, 50, 100] as const;
export type PageSizeOption = (typeof pageSizes)[number];
const pageSizeOptions = pageSizes.map((n) => ({
  label: n.toString(),
  value: n,
}));

export function TableContent({
  columnOrder,
  columns,
  customPageComponent,
  customRowRenderer,
  data,
  defaultSort,
  emptyTableElement,
  error,
  hideRowCounts,
  isError,
  isLoading,
  isSortable,
  onPageChange,
  onSortChange,
  pageInfo,
  tableContainerProps,
  tableHeaderProps,
  tableRowProps,
  title,
  subHeader,
  setHoveredRow,
  ...tableProps
}: WMSTableProps) {
  const tableColumns = useMemo(() => columns.filter(({ hide }) => !hide), [columns]);
  const tableData = useMemo(() => {
    return isLoading ? Array(5).fill({}) : (data ?? []);
  }, [data, isLoading]);

  const {
    getTableProps,
    getTableBodyProps,
    gotoPage,
    headerGroups,
    rows,
    prepareRow,
    state: { pageIndex, pageSize, sortBy },
    previousPage,
    nextPage,
    setPageSize,
    canPreviousPage,
    canNextPage,
    setColumnOrder,
  } = useTable(
    {
      data: tableData,
      columns: tableColumns as any, // This is the easiest way to make this work. Otherwise we'd have to modify the react-table types even further.
      initialState: {
        pageSize: pageInfo?.page_size ?? 20,
        pageIndex: pageInfo?.page_number ? pageInfo.page_number - 1 : 1,
        sortBy: defaultSort ? sortingFromParams(defaultSort) : [],
      },
      manualPagination: true,
      manualSortBy: isSortable,
      // remove this when we support multi sort
      disableMultiSort: true,
      // since we will use default sorts for all tables, we do not want to mess with removing sort
      disableSortRemove: true,
      pageCount: pageInfo?.total_pages,
    },
    useSortBy,
    usePagination, // this needs to be after useSortBy
    useColumnOrder
  );

  useEffect(() => {
    columnOrder && setColumnOrder(columnOrder);
  }, [columnOrder, setColumnOrder]);

  const previousSortBy = usePrevious(sortBy);

  useEffect(() => {
    if (previousSortBy === undefined || sortBy === previousSortBy) return;

    sortBy?.length && onSortChange?.(sortingToParams(sortBy));
  }, [onSortChange, previousSortBy, sortBy]);

  const previousPageNumber = usePrevious(pageIndex);
  const previousPageSize = usePrevious(pageSize);

  useEffect(() => {
    if (
      previousPageNumber === undefined ||
      (previousPageNumber === pageIndex && previousPageSize === pageSize) ||
      isLoading
    )
      return;

    onPageChange?.({ page_number: pageIndex + 1, page_size: pageSize });
  }, [isLoading, onPageChange, pageIndex, pageSize, previousPageNumber, previousPageSize]);

  const handlePageSizeChanged = (pageSize: number) => {
    const size = typeof pageSize === 'string' ? parseInt(pageSize) : pageSize;
    setPageSize(size);
    // Reset to first page when changing page size
    gotoPage(0);
  };

  const goToNextPage = useCallback(() => {
    nextPage();
    scrollToPageHeader();
  }, [nextPage]);

  const goToPreviousPage = useCallback(() => {
    previousPage();
    scrollToPageHeader();
  }, [previousPage]);

  const tableBorderColor = mode('gray.200', 'whiteAlpha.300');

  return isError ? (
    <GenericError h="60vh" error={error} />
  ) : (
    <Box data-testid={isLoading ? 'loading' : ''}>
      {title && <Heading size="md">{title}</Heading>}
      <Box
        borderColor={tableBorderColor}
        borderRadius="4px"
        borderWidth="1px"
        my={4}
        overflowX="auto"
        {...tableContainerProps}
      >
        <Table fontSize="sm" {...tableProps} {...getTableProps()}>
          <Thead {...tableHeaderProps}>
            {headerGroups.map((headerGroup, index) => (
              <Tr {...headerGroup.getHeaderGroupProps()} key={index}>
                {headerGroup.headers.map((column, index) => {
                  const columnHeaderProps = isSortable
                    ? column.getHeaderProps(column.getSortByToggleProps())
                    : column.getHeaderProps();
                  return (
                    <Th
                      whiteSpace="nowrap"
                      scope="col"
                      p={4}
                      {...columnHeaderProps}
                      {...tableHeaderProps}
                      key={index}
                    >
                      <Flex alignItems="center">
                        <>
                          {column.render('Header')}
                          {isSortable && (
                            <>
                              {column.isSorted &&
                                (column.isSortedDesc ? (
                                  <FaArrowDown
                                    style={{
                                      display: 'inline-block',
                                      marginLeft: '0.5rem',
                                    }}
                                  />
                                ) : (
                                  <FaArrowUp
                                    style={{
                                      display: 'inline-block',
                                      marginLeft: '0.5rem',
                                    }}
                                  />
                                ))}
                            </>
                          )}
                        </>
                      </Flex>
                    </Th>
                  );
                })}
              </Tr>
            ))}
          </Thead>
          <Tbody {...getTableBodyProps()} data-testid="table-body">
            {subHeader && <Tr>{subHeader}</Tr>}
            {rows.length === 0 && (
              <Tr>
                <Td colSpan={100}>
                  {emptyTableElement ?? (
                    <Text variant="lighter" textAlign="center">
                      No data to display
                    </Text>
                  )}
                </Td>
              </Tr>
            )}
            {customRowRenderer && !isLoading
              ? customRowRenderer(rows)
              : rows.map((row, index) => {
                  prepareRow(row);

                  return (
                    <Tr
                      {...row.getRowProps()}
                      {...tableRowProps}
                      {...(setHoveredRow
                        ? {
                            onMouseEnter: () => setHoveredRow(row.index),
                            onMouseLeave: () => setHoveredRow(null),
                          }
                        : {})}
                      key={index}
                    >
                      {row.cells.map((cell, index) => {
                        const showLoading = isLoading || cell?.column?.isLoading;

                        return (
                          <Td {...cell.getCellProps()} p={4} key={index}>
                            {showLoading ? (
                              <Skeleton h={4} data-testid="loading" />
                            ) : (
                              cell.render('Cell', cell.row.original)
                            )}
                          </Td>
                        );
                      })}
                    </Tr>
                  );
                })}
          </Tbody>
        </Table>
      </Box>
      {customPageComponent ||
        (pageInfo && (
          <HStack justify="end" spacing={2} py={4} alignItems="center">
            <Text fontSize="sm" color="gray" data-testid="pagination">
              Page <NoTranslate>{pageInfo.page_number}</NoTranslate> of{' '}
              <NoTranslate>{pageInfo.total_pages}</NoTranslate>{' '}
              {!hideRowCounts && (
                <>
                  (<NoTranslate>{pageInfo.total_entries ?? 0}</NoTranslate> total rows)
                </>
              )}
            </Text>
            <Button
              variant="outline"
              colorScheme="gray"
              onClick={goToPreviousPage}
              isDisabled={!canPreviousPage}
              size="sm"
              aria-label="Previous page"
            >
              <FaArrowLeft />
            </Button>
            <Button
              variant="outline"
              colorScheme="gray"
              onClick={goToNextPage}
              isDisabled={!canNextPage}
              size="sm"
              aria-label="Next page"
            >
              <FaArrowRight />
            </Button>
            {!hideRowCounts && (
              <DropdownFilter<number>
                width="auto"
                aria-label="Results per page"
                value={pageSize}
                onChange={handlePageSizeChanged}
                options={pageSizeOptions}
              />
            )}
          </HStack>
        ))}
    </Box>
  );
}
