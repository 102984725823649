import type { LocationType } from '@store/services/api.generated';
import * as yup from 'yup';

const yupEmptyStringOrNumber = yup.lazy((value) =>
  typeof value === 'string'
    ? yup
        .string()
        .transform((value, originalValue) => (String(originalValue).trim() === '' ? null : value))
    : yup
        .number()
        .integer('Must be a valid number.')
        .typeError('Must be a valid number.')
        .nullable()
);

export const updateLocationSchema = yup.object({
  location_sequence: yup
    .number()
    .integer('Must be whole number')
    .positive('Must be greater than 0')
    .required()
    .typeError('Must be greater than 0'),
  active: yup.boolean().required(),
  type: yup
    .mixed<LocationType>()
    .oneOf(['equipment', 'pending', 'shipped', 'staging', 'storage', 'other'])
    .required(),
  subtype: yup.string().nullable(),
  pick_equipment_group_id: yup.string().nullable(),
  max_capacity: yupEmptyStringOrNumber,
  replenishment_threshold: yupEmptyStringOrNumber,
  zone_id: yup.string().nullable(),
});
