import { Loading } from '@components/Loading';
import { GenericError } from '@components/GenericError';
import type { LoadingProps } from '@components/Loading';
import type { GenericErrorProps } from '@components/GenericError';
import type { CenterProps } from '@chakra-ui/react';

export type LoadingOrErrorProps = {
  isError?: boolean;
  errorLayoutProps?: CenterProps;
} & LoadingProps &
  GenericErrorProps;

export const LoadingOrError = ({
  children,
  containerProps,
  error,
  errorLayoutProps,
  isError = false,
  isLoading,
  showGoBackButton,
  showRefreshButton,
  size,
  title,
  ...loadingLayoutProps
}: LoadingOrErrorProps) => (
  <Loading
    isLoading={isLoading}
    size={size}
    containerProps={containerProps}
    {...loadingLayoutProps}
  >
    {isError ? (
      <GenericError
        title={title}
        error={error}
        showGoBackButton={showGoBackButton}
        showRefreshButton={showRefreshButton}
        {...errorLayoutProps}
      />
    ) : (
      children
    )}
  </Loading>
);

export default LoadingOrError;
