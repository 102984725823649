import { Center, Spinner } from '@chakra-ui/react';
import type { CenterProps, SpinnerProps } from '@chakra-ui/react';

export type LoadingProps = CenterProps & {
  isLoading?: boolean;
  size?: SpinnerProps['size'];
  containerProps?: CenterProps;
};
export const Loading = ({
  children = <div />,
  isLoading = false,
  size = 'xl',
  containerProps,
  h = '400px',
}: LoadingProps) => {
  return isLoading ? (
    <Center h={h} {...containerProps} data-testid="loading">
      <Spinner thickness="3px" emptyColor="blue.100" color="blue.500" size={size} />
    </Center>
  ) : (
    children
  );
};
