import * as yup from 'yup';

export const s1cMappingSchema = yup.object({
  building_id: yup.string().required('Building is required'),
  shipper_id: yup.string().required('Shipper is required'),
  warehouse_alias: yup.string().required('Warehouse alias is required'),
  distribution_network_id: yup.string().required('Distribution network is required'),
  facility_id: yup.string().required('Facility is required'),
  tenant_id: yup.string(),
  orion_version: yup.mixed().oneOf(['1', '2'], 'S1C version must be 1 or 2'),
  active: yup.mixed().oneOf(['true', 'false', '']).required(),
});
