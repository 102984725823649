import type { KnownTopic } from '@features/realtime/realtimeSlice';
import { resetTopicCount as resetTopicCountAction } from '@features/realtime/realtimeSlice';
import type { BaseQueryArg } from '@reduxjs/toolkit/dist/query/baseQueryTypes';
import type { QueryLifecycleApi } from '@reduxjs/toolkit/dist/query/endpointDefinitions';
import type {
  Attachment,
  GetBinLocationsDownloadApiArg,
  GetInboundsDownloadApiArg,
  GetItemsDownloadApiArg,
  GetLocationsDownloadApiArg,
  GetOutboundsByOutboundIdParcelsAndParcelIdPackingSlipApiArg,
  GetOutboundsDownloadApiArg,
  GetProductsDownloadApiArg,
  GetUsersDownloadApiArg,
  PostKitsByKitIdAttachmentsApiArg,
  PostOutboundsByOutboundIdAttachmentsApiArg,
  PostPackingSlipTemplatesByIdLogoApiArg,
  PostWorkOrdersByWorkOrderIdAttachmentsApiArg,
} from './api.generated';
import { generatedApi } from './api.generated';
import { fetchOverrideConfig, fetchStaticConfig } from './utils';
import { convertKeysToSnakeCase } from '@utils';
import type { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';

const resetTopicCount =
  <T extends BaseQueryArg<any> = any>(topic: KnownTopic) =>
  async (_arg: T, api: QueryLifecycleApi<T, any, any, any>) => {
    // We want to ignore the errors here as it'll actually `throw` the final result of our `baseQuery`, and we have no good way of
    // catching it. This behavior allows things to work as expected.
    try {
      await api.queryFulfilled;
      api.dispatch(resetTopicCountAction(topic));
      // eslint-disable-next-line no-empty
    } catch (err) {}
  };

// Being that we're using an autogenerated API client, we can leverage .injectEndpoints
// and .enhanceEndpoints here as the need arises.

export const TAG_TYPES = [
  'Adjustment',
  'Announcement',
  'Attachment',
  'AutomationConfig',
  'BarcodeSequence',
  'BroadcastNotification',
  'BuildingAnnouncement',
  'Buildings',
  'Carrier',
  'CycleCount',
  'CycleCountLocation',
  'Exception',
  'InboundLineItem',
  'InboundOrder',
  'InboundOrderLine',
  'Inventory',
  'Item',
  'Kit',
  'LicensePlate',
  'Location',
  'LotNumber',
  'Movement',
  'OutboundOrder',
  'OutboundOrderLineItem',
  'Parcel',
  'PackingSlipTemplate',
  'PackingSlipTemplateChannel',
  'Pick',
  'PickBatch',
  'PickEquipmentGroup',
  'PickPath',
  'Printer',
  'Product',
  'ProductLotNumber',
  'Receipt',
  'ReceiptLine',
  'ReplenishmentLookAhead',
  'S1CWarehouseMapping',
  'SerialNumber',
  'Shipper',
  'ShipperAddress',
  'ShipperConfig',
  'SpecialInstructions',
  'StreamList',
  'Tracking',
  'UnitOfMeasure',
  'User',
  'WorkOrder',
  'Packaging',
  'Zone',
] as const;

type KnownTag = (typeof TAG_TYPES)[number];

const invalidateTags =
  (tags: KnownTag[], invalidateOnError: KnownTag[] = []) =>
  (_result: unknown, error?: FetchBaseQueryError) =>
    !error ? tags : invalidateOnError;

/**
 * Notes on invalidation
 *
 * In order to make things more clear and account for any api inconsistencies, we're going to use the following pattern:
 *
 * providesTags: ['Entity'],
 * invalidatesTags: invalidateTags(['Entity']);
 *
 * This will help ensure as this file grows, developers will not need to remember if the given endpoint has a response body or not,
 * only that it was successful.
 *
 * @see https://redux-toolkit.js.org/rtk-query/usage/automated-refetching
 */
const api = generatedApi
  .enhanceEndpoints({
    addTagTypes: TAG_TYPES,
    endpoints: {
      getBuildings: {
        onQueryStarted: resetTopicCount('buildings'),
        providesTags: ['Buildings'],
      },
      patchBuildingsByBuildingId: {
        invalidatesTags: invalidateTags(['Buildings']),
      },
      postBuildings: {
        invalidatesTags: invalidateTags(['Buildings']),
      },
      postBroadcasts: {
        invalidatesTags: invalidateTags(['BroadcastNotification']),
      },
      getUsers: {
        providesTags: ['User'],
        onQueryStarted: resetTopicCount('users'),
      },
      postUsers: {
        invalidatesTags: invalidateTags(['User']),
      },
      patchUsersByUserId: {
        invalidatesTags: invalidateTags(['User']),
      },
      getLocations: {
        providesTags: ['Location'],
        onQueryStarted: resetTopicCount('locations'),
      },
      getLocationsByLocationId: {
        providesTags: ['Location'],
      },
      patchLocationsByLocationId: {
        invalidatesTags: invalidateTags(['Location']),
      },
      patchLocationsBulkAssignZone: {
        invalidatesTags: invalidateTags(['Location']),
      },
      postLocationsUpload: {
        invalidatesTags: invalidateTags(['Location']),
      },
      getZones: {
        providesTags: ['Zone'],
        onQueryStarted: resetTopicCount('zones'),
      },
      postZones: {
        invalidatesTags: invalidateTags(['Zone']),
      },
      patchZonesByZoneId: {
        invalidatesTags: invalidateTags(['Zone']),
      },
      postZonesLock: {
        invalidatesTags: invalidateTags(['Zone']),
      },
      postZonesUnlock: {
        invalidatesTags: invalidateTags(['Zone']),
      },
      getPrinters: {
        providesTags: ['Printer'],
        onQueryStarted: resetTopicCount('printers'),
      },
      postPrinters: {
        invalidatesTags: invalidateTags(['Printer']),
      },
      patchPrintersByPrinterId: {
        invalidatesTags: invalidateTags(['Printer']),
      },
      deletePrintersByPrinterId: {
        invalidatesTags: invalidateTags(['Printer']),
      },
      postItemsByItemIdAdjustments: {
        invalidatesTags: invalidateTags([
          'Adjustment',
          'Item',
          'LicensePlate',
          'OutboundOrder',
          'Product',
        ]),
      },
      postKits: {
        invalidatesTags: invalidateTags(['Kit', 'Product']),
      },
      patchKitsByKitId: {
        invalidatesTags: invalidateTags(['Kit', 'Product']),
      },
      getKitsByKitId: {
        providesTags: ['Kit', 'Product'],
      },
      getAdjustments: {
        providesTags: ['Adjustment'],
      },
      getProductsByProductId: {
        providesTags: ['Product'],
        onQueryStarted: resetTopicCount('product_units_of_measure'),
      },
      getShippersByShipperNameProductsAndSku: {
        providesTags: ['Product'],
      },
      patchProducts: {
        invalidatesTags: invalidateTags(['Product', 'LicensePlate', 'Location']),
      },
      postProducts: {
        invalidatesTags: invalidateTags(['Product']),
      },
      deleteProductsByProductId: {
        invalidatesTags: invalidateTags(['Product']),
      },
      patchShippersByShipperNameProductsAndSku: {
        invalidatesTags: invalidateTags(['Product', 'LicensePlate', 'Location']),
      },
      postProductsByProductIdBarcodes: {
        invalidatesTags: invalidateTags(['Product']),
      },
      patchProductsByProductIdBarcodesAndProductBarcodeId: {
        invalidatesTags: invalidateTags(['Product']),
      },
      deleteProductsByProductIdBarcodesAndProductBarcodeId: {
        invalidatesTags: invalidateTags(['Product']),
      },
      getInbounds: {
        providesTags: ['InboundOrder'],
        onQueryStarted: resetTopicCount('inbounds'),
      },
      getInboundsByInboundOrderId: {
        providesTags: ['InboundOrder'],
      },
      getShippersByShipperNameInboundsAndOrderNumber: {
        providesTags: ['InboundOrder'],
      },
      patchInboundsByInboundOrderId: {
        invalidatesTags: invalidateTags(['InboundOrder']),
      },
      getInboundsByInboundOrderIdLines: {
        providesTags: ['InboundOrderLine'],
      },
      getInboundsByInboundOrderIdItems: {
        providesTags: ['InboundLineItem'],
      },
      postInboundsByInboundOrderIdLinesAndInboundLineIdLpns: {
        invalidatesTags: invalidateTags([
          'InboundOrder',
          'Item',
          'InboundLineItem',
          'InboundOrderLine',
          'Receipt',
        ]),
      },
      patchInboundsByInboundOrderIdLinesAndInboundLineIdLpnsLicensePlateIdItemsItemId: {
        invalidatesTags: invalidateTags([
          'InboundOrder',
          'Item',
          'InboundLineItem',
          'InboundOrderLine',
          'Receipt',
        ]),
      },
      deleteInboundsByInboundOrderIdLinesAndInboundLineIdLpnsLicensePlateIdItemsItemId: {
        invalidatesTags: invalidateTags([
          'InboundOrder',
          'Item',
          'InboundLineItem',
          'InboundOrderLine',
          'Receipt',
        ]),
      },
      putLicensePlatesByLicensePlateIdMove: {
        invalidatesTags: invalidateTags(['LicensePlate', 'Location', 'Item', 'Movement']),
      },
      getInboundsByInboundOrderIdLinesAndInboundLineId: {
        providesTags: ['InboundOrder'],
      },
      patchInboundsByInboundOrderIdLinesAndInboundLineId: {
        invalidatesTags: invalidateTags(['InboundOrder']),
      },
      postInboundExceptions: {
        invalidatesTags: invalidateTags(['InboundOrder']),
      },
      getMovements: {
        providesTags: ['Movement'],
      },
      postInbounds: {
        invalidatesTags: invalidateTags(['InboundOrder']),
      },
      getOutbounds: {
        providesTags: ['OutboundOrder'],
        onQueryStarted: resetTopicCount('outbounds'),
      },
      getOutboundsByOrderId: {
        providesTags: ['OutboundOrder'],
      },
      getShippersByShipperNameOutboundsAndOrderNumber: {
        providesTags: ['OutboundOrder'],
      },
      postOutbounds: {
        invalidatesTags: invalidateTags(['OutboundOrder']),
      },
      patchOutboundsByOrderId: {
        invalidatesTags: invalidateTags(['OutboundOrder', 'PickBatch']),
      },
      postOutboundsShip: {
        invalidatesTags: invalidateTags(['OutboundOrder']),
      },
      postOutboundsByOrderIdShipments: {
        invalidatesTags: invalidateTags(['OutboundOrder']),
      },
      getOutboundsByOutboundIdParcels: {
        providesTags: ['Parcel'],
      },
      postOutboundsByOutboundIdParcels: {
        invalidatesTags: invalidateTags(['OutboundOrder', 'Parcel', 'PickBatch']),
      },
      postOutboundsByOutboundIdParcelsAndParcelIdGenerateLabel: {
        invalidatesTags: invalidateTags(['OutboundOrder', 'Parcel', 'PickBatch']),
      },
      deleteOutboundsByOutboundIdParcelsAndParcelId: {
        invalidatesTags: invalidateTags(['OutboundOrder', 'Parcel']),
      },
      getOutboundsByOutboundIdAttachments: {
        providesTags: ['Attachment'],
      },
      postOutboundsByOutboundIdAttachments: {
        invalidatesTags: invalidateTags(['Attachment']),
      },
      getAttachmentsByAttachmentId: {
        providesTags: ['Attachment'],
      },
      deleteAttachmentsByAttachmentId: {
        invalidatesTags: invalidateTags(['Attachment']),
      },
      patchOutboundsByOrderIdShipmentsAndShipmentId: {
        invalidatesTags: invalidateTags(['OutboundOrder']),
      },
      postOutboundsByOrderIdLinesAndOutboundLineIdPick: {
        invalidatesTags: invalidateTags(
          [
            'OutboundOrder',
            'Location',
            'PickBatch',
            'PickPath',
            'Pick',
            'LicensePlate',
            'OutboundOrderLineItem',
            'Item',
          ],
          ['PickPath']
        ),
      },
      postBatchesByBatchIdPick: {
        invalidatesTags: invalidateTags(
          [
            'OutboundOrder',
            'Location',
            'PickBatch',
            'PickPath',
            'Pick',
            'LicensePlate',
            'Item',
            'OutboundOrderLineItem',
          ],
          ['PickPath']
        ),
      },
      postBatches: {
        invalidatesTags: invalidateTags(['OutboundOrder', 'PickBatch', 'Pick', 'StreamList']),
      },
      postBatchesBulk: {
        invalidatesTags: invalidateTags(['OutboundOrder', 'PickBatch', 'StreamList']),
      },
      postOutboundsUnbatch: {
        invalidatesTags: invalidateTags(['OutboundOrder', 'PickBatch', 'Pick', 'StreamList']),
      },
      postOutboundsUnpick: {
        invalidatesTags: invalidateTags(['OutboundOrder', 'Item', 'Pick']),
      },
      postOutboundsStage: {
        invalidatesTags: invalidateTags([
          'Location',
          'PickBatch',
          'PickPath',
          'OutboundOrder',
          'LicensePlate',
        ]),
      },
      patchBatchesByBatchId: {
        invalidatesTags: invalidateTags(['PickBatch', 'OutboundOrder', 'StreamList']),
      },
      postBatchesByBatchIdShortSplit: {
        invalidatesTags: invalidateTags(['OutboundOrder', 'PickBatch', 'Pick', 'PickPath']),
      },
      postBatchesByBatchIdRelease: {
        invalidatesTags: invalidateTags(['OutboundOrder', 'PickBatch', 'Pick', 'PickPath']),
      },
      getProducts: {
        providesTags: ['Product'],
        onQueryStarted: resetTopicCount('products'),
      },
      postProductsUpload: {
        invalidatesTags: invalidateTags(['Product']),
      },
      getItems: {
        providesTags: ['Item'],
        onQueryStarted: resetTopicCount('items'),
      },
      getItemsByItemId: {
        providesTags: ['Item'],
      },
      patchItemsByItemId: {
        invalidatesTags: invalidateTags([
          'Item',
          'LicensePlate',
          'InboundLineItem',
          'OutboundOrderLineItem',
          'Receipt',
        ]),
      },
      postItems: {
        invalidatesTags: ['Item', 'LicensePlate'],
      },
      getActivities: {
        onQueryStarted: resetTopicCount('activities'),
      },
      patchCycleCountsByCycleCountId: {
        invalidatesTags: invalidateTags(['CycleCount']),
      },
      postCycleCountsBulk: {
        invalidatesTags: invalidateTags(['CycleCount', 'Location']),
      },
      getCycleCounts: {
        providesTags: ['CycleCount'],
        onQueryStarted: resetTopicCount('cycle_counts'),
      },
      getCycleCountsByCycleCountId: {
        providesTags: ['CycleCount'],
      },
      postCycleCounts: {
        invalidatesTags: invalidateTags(['CycleCount']),
      },
      patchCycleCountsAssign: {
        invalidatesTags: invalidateTags(['CycleCount']),
      },
      patchCycleCountsByCycleCountIdLocationsAndCycleCountLocationIdResultsCycleCountResultId: {
        invalidatesTags: invalidateTags(['CycleCount', 'CycleCountLocation']),
      },
      deleteCycleCountsByCycleCountIdLocationsAndCycleCountLocationIdResultsCycleCountResultId: {
        invalidatesTags: invalidateTags(['CycleCount', 'CycleCountLocation']),
      },
      getBatches: {
        providesTags: ['StreamList'],
      },
      getBatchesByBatchId: {
        providesTags: ['PickBatch'],
      },
      getLicensePlates: {
        providesTags: ['LicensePlate'],
        onQueryStarted: resetTopicCount('license_plates'),
      },
      getLicensePlatesByLicensePlateId: {
        providesTags: ['LicensePlate'],
      },
      getLicensePlatesNumberByLicensePlateNumber: {
        providesTags: ['LicensePlate'],
      },
      patchLicensePlatesByLicensePlateId: {
        invalidatesTags: invalidateTags(['LicensePlate', 'Item']),
      },
      postLicensePlatesByLicensePlateIdSplit: {
        invalidatesTags: invalidateTags(['LicensePlate', 'Location', 'Item']),
      },
      postLicensePlatesByLicensePlateIdReplace: {
        invalidatesTags: invalidateTags(['LicensePlate', 'Location', 'Item']),
      },
      getShippers: {
        providesTags: ['Shipper'],
        onQueryStarted: resetTopicCount('shippers'),
      },
      patchShippersByShipperId: {
        invalidatesTags: invalidateTags(['Shipper']),
      },
      postShippers: {
        invalidatesTags: invalidateTags(['Shipper']),
      },
      getUom: {
        providesTags: ['UnitOfMeasure'],
        onQueryStarted: resetTopicCount('units_of_measure'),
      },
      postUom: {
        invalidatesTags: invalidateTags(['UnitOfMeasure']),
      },
      deleteUomByUomId: {
        invalidatesTags: invalidateTags(['UnitOfMeasure']),
      },
      deleteProductsByProductIdUomAndProductUomId: {
        invalidatesTags: invalidateTags(['Product']),
      },
      patchProductsByProductIdUomAndProductUomId: {
        invalidatesTags: invalidateTags(['Product']),
      },
      postProductsByProductIdUom: {
        invalidatesTags: invalidateTags(['Product']),
      },
      getShippersByShipperIdAddresses: {
        providesTags: ['ShipperAddress'],
      },
      postShippersByShipperIdAddresses: {
        invalidatesTags: invalidateTags(['ShipperAddress']),
      },
      patchShippersByShipperIdAddressesAndShipperAddressId: {
        invalidatesTags: invalidateTags(['ShipperAddress']),
      },
      deleteShippersByShipperIdAddressesAndShipperAddressId: {
        invalidatesTags: invalidateTags(['ShipperAddress']),
      },
      getShipperConfigs: {
        providesTags: ['ShipperConfig'],
      },
      getShippersByShipperIdConfig: {
        providesTags: ['ShipperConfig'],
      },
      postShippersByShipperIdConfig: {
        invalidatesTags: invalidateTags(['ShipperConfig']),
      },
      patchShippersByShipperIdConfig: {
        invalidatesTags: invalidateTags(['ShipperConfig']),
      },
      patchShippersByShipperIdConfigPremiumFeatures: {
        invalidatesTags: invalidateTags(['ShipperConfig']),
      },
      getShippersByShipperIdSpecialInstructions: {
        providesTags: ['SpecialInstructions'],
      },
      postShippersByShipperIdSpecialInstructions: {
        invalidatesTags: invalidateTags(['SpecialInstructions']),
      },
      patchShippersByShipperIdSpecialInstructionsAndSpecialInstructionId: {
        invalidatesTags: invalidateTags(['SpecialInstructions']),
      },
      deleteShippersByShipperIdSpecialInstructionsAndSpecialInstructionId: {
        invalidatesTags: invalidateTags(['SpecialInstructions']),
      },
      getTracking: {
        providesTags: ['Tracking'],
      },
      getOrionWarehouseMappings: {
        providesTags: ['S1CWarehouseMapping'],
      },
      patchOrionWarehouseMappingsByOrionWarehouseMappingId: {
        invalidatesTags: invalidateTags(['S1CWarehouseMapping']),
      },
      postOrionWarehouseMappings: {
        invalidatesTags: invalidateTags(['S1CWarehouseMapping']),
      },
      getInboundsByInboundOrderIdReceipts: {
        providesTags: ['Receipt'],
      },
      patchInboundsByInboundOrderIdReceiptsAndReceiptId: {
        invalidatesTags: invalidateTags(['InboundOrder', 'Receipt']),
      },
      getInboundsReceipts: {
        providesTags: ['Receipt'],
      },
      getReceiptsByReceiptIdReceiptLines: {
        providesTags: ['ReceiptLine'],
      },
      getWorkOrders: {
        providesTags: ['WorkOrder'],
      },
      getWorkOrdersReplenishmentLookAhead: {
        providesTags: ['ReplenishmentLookAhead'],
      },
      getShippersByShipperNameWorkOrdersAndNumber: {
        providesTags: ['WorkOrder'],
      },
      postWorkOrders: {
        invalidatesTags: invalidateTags(['WorkOrder', 'ReplenishmentLookAhead']),
      },
      postWorkOrdersByWorkOrderIdPick: {
        invalidatesTags: invalidateTags([
          'WorkOrder',
          'LicensePlate',
          'Item',
          'ReplenishmentLookAhead',
        ]),
      },
      postWorkOrdersByWorkOrderIdReplenish: {
        invalidatesTags: invalidateTags([
          'WorkOrder',
          'LicensePlate',
          'Item',
          'ReplenishmentLookAhead',
        ]),
      },
      postWorkOrdersStageReplenishments: {
        invalidatesTags: invalidateTags([
          'WorkOrder',
          'LicensePlate',
          'Item',
          'ReplenishmentLookAhead',
        ]),
      },
      postWorkOrdersByWorkOrderIdStage: {
        invalidatesTags: invalidateTags(['WorkOrder', 'LicensePlate', 'ReplenishmentLookAhead']),
      },
      patchWorkOrdersByWorkOrderId: {
        invalidatesTags: invalidateTags(['WorkOrder', 'ReplenishmentLookAhead']),
      },
      getWorkOrdersByWorkOrderId: {
        providesTags: ['WorkOrder'],
      },
      getKitsByKitIdAttachments: {
        providesTags: ['Attachment'],
      },
      postKitsByKitIdAttachments: {
        invalidatesTags: invalidateTags(['Attachment']),
      },
      getWorkOrdersByWorkOrderIdAttachments: {
        providesTags: ['Attachment'],
      },
      postWorkOrdersByWorkOrderIdAssembleKit: {
        invalidatesTags: invalidateTags(['Item', 'WorkOrder']),
      },
      postWorkOrdersByWorkOrderIdMove: {
        invalidatesTags: invalidateTags(['WorkOrder', 'LicensePlate']),
      },
      patchWorkOrderStepsByWorkOrderStepIdAssign: {
        invalidatesTags: invalidateTags(['WorkOrder']),
      },
      patchWorkOrdersAssign: {
        invalidatesTags: invalidateTags(['WorkOrder']),
      },
      getShippingPackaging: {
        providesTags: ['Packaging'],
      },
      getShippingPackagingByPackagingId: {
        providesTags: ['Packaging'],
      },
      patchShippingPackagingByPackagingId: {
        invalidatesTags: invalidateTags(['Packaging']),
      },
      postShippingPackaging: {
        invalidatesTags: invalidateTags(['Packaging']),
      },
      deleteShippingPackagingByPackagingId: {
        invalidatesTags: invalidateTags(['Packaging']),
      },
      getPackingSlipTemplates: {
        providesTags: ['PackingSlipTemplate', 'PackingSlipTemplateChannel'],
      },
      getPackingSlipTemplatesById: {
        providesTags: ['PackingSlipTemplate', 'PackingSlipTemplateChannel'],
      },
      patchPackingSlipTemplatesById: {
        invalidatesTags: invalidateTags(['PackingSlipTemplate', 'PackingSlipTemplateChannel']),
      },
      deletePackingSlipTemplatesById: {
        invalidatesTags: invalidateTags(['PackingSlipTemplate', 'PackingSlipTemplateChannel']),
      },
      deletePackingSlipTemplatesByIdLogo: {
        invalidatesTags: invalidateTags(['Attachment', 'PackingSlipTemplate']),
      },
      postPackingSlipTemplates: {
        invalidatesTags: invalidateTags(['PackingSlipTemplate', 'PackingSlipTemplateChannel']),
      },
      postPackingSlipTemplatesByPackingSlipTemplateIdChannels: {
        invalidatesTags: invalidateTags(['PackingSlipTemplateChannel']),
      },
      deletePackingSlipTemplatesByPackingSlipTemplateIdChannelsAndId: {
        invalidatesTags: invalidateTags(['PackingSlipTemplateChannel']),
      },
      getBatchesByBatchIdPickPath: {
        providesTags: ['PickPath'],
      },
      postBatchesByBatchIdAlternatePick: {
        invalidatesTags: invalidateTags(['Exception', 'PickPath', 'Pick']),
      },
      deletePickByPickId: {
        invalidatesTags: invalidateTags(['Exception', 'PickPath', 'Pick']),
      },
      patchPickByPickId: {
        invalidatesTags: invalidateTags(['Exception', 'PickPath', 'Pick']),
      },
      getOutboundsLineItems: {
        providesTags: ['OutboundOrderLineItem'],
      },
      getOutboundsByOrderIdLineItems: {
        providesTags: ['OutboundOrderLineItem'],
      },
      postOutboundExceptions: {
        invalidatesTags: invalidateTags(['Exception', 'PickPath', 'Pick']),
      },
      getOutboundExceptions: {
        providesTags: ['Exception'],
      },
      getOutboundExceptionsById: {
        providesTags: ['Exception'],
      },
      getSerialNumbers: {
        providesTags: ['SerialNumber'],
      },
      postSerialNumbers: {
        invalidatesTags: invalidateTags(['SerialNumber']),
      },
      deleteSerialNumbersBySerialNumberId: {
        invalidatesTags: invalidateTags(['SerialNumber']),
      },
      patchPackoutOutboundsByOutboundIdPacked: {
        invalidatesTags: invalidateTags(['OutboundOrder']),
      },
      postLicensePlatesLock: {
        invalidatesTags: invalidateTags(['LicensePlate', 'Item', 'InboundLineItem']),
      },
      getShippersByShipperNameProductsAndSkuLocationSummary: {
        providesTags: ['Product', 'Item', 'Location'],
      },
      postLicensePlatesUnlock: {
        invalidatesTags: invalidateTags(['Item', 'LicensePlate', 'InboundLineItem']),
      },
      getAnnouncements: {
        providesTags: ['Announcement'],
      },
      getAnnouncementsByAnnouncementId: {
        providesTags: ['Announcement'],
      },
      postAnnouncements: {
        invalidatesTags: invalidateTags(['Announcement']),
      },
      patchAnnouncementsByAnnouncementId: {
        invalidatesTags: invalidateTags(['Announcement']),
      },
      deleteAnnouncementsByAnnouncementId: {
        invalidatesTags: invalidateTags(['Announcement', 'BuildingAnnouncement']),
      },
      getBuildingAnnouncements: {
        providesTags: ['BuildingAnnouncement'],
      },
      postBuildingAnnouncements: {
        invalidatesTags: invalidateTags(['BuildingAnnouncement']),
      },
      postBuildingAnnouncementsBulk: {
        invalidatesTags: invalidateTags(['BuildingAnnouncement']),
      },
      getAutomationConfigs: {
        providesTags: ['AutomationConfig'],
      },
      getAutomationConfigsByAutomationConfigId: {
        providesTags: ['AutomationConfig'],
      },
      postAutomationConfigs: {
        invalidatesTags: invalidateTags(['AutomationConfig']),
      },
      patchAutomationConfigsByAutomationConfigId: {
        invalidatesTags: invalidateTags(['AutomationConfig']),
      },
      deleteAutomationConfigsByAutomationConfigId: {
        invalidatesTags: invalidateTags(['AutomationConfig']),
      },
      postAutomationConfigsByAutomationConfigIdRestream: {
        invalidatesTags: invalidateTags(['OutboundOrder', 'PickBatch']),
      },
      getPicks: {
        providesTags: ['Pick'],
      },
      getProductLotNumbers: {
        providesTags: ['ProductLotNumber'],
      },
      patchShippersByShipperNameProductsAndSkuLotNumbersLotNumber: {
        invalidatesTags: invalidateTags(['ProductLotNumber']),
      },
      postShippersByShipperNameProductsAndSkuLotNumbers: {
        invalidatesTags: invalidateTags(['ProductLotNumber']),
      },
      getItemsLotNumbers: {
        providesTags: ['LotNumber'],
      },
      postShippersByShipperIdObfuscate: {
        invalidatesTags: invalidateTags([
          'InboundOrder',
          'OutboundOrder',
          'ShipperAddress',
          'Parcel',
        ]),
      },
      postShippersByShipperIdPurgeInventory: {
        invalidatesTags: invalidateTags(['Adjustment', 'Item', 'Kit', 'Inventory', 'LicensePlate']),
      },
      postShippersByShipperIdOutboundsShip: {
        invalidatesTags: invalidateTags(['OutboundOrder']),
      },
      getPickEquipmentGroups: {
        providesTags: ['PickEquipmentGroup'],
      },
      postPickEquipmentGroups: {
        invalidatesTags: invalidateTags(['PickEquipmentGroup']),
      },
      patchPickEquipmentGroupsByPickEquipmentGroupId: {
        invalidatesTags: invalidateTags(['PickEquipmentGroup']),
      },
      deletePickEquipmentGroupsByPickEquipmentGroupId: {
        invalidatesTags: invalidateTags(['PickEquipmentGroup']),
      },
      getBarcodeSequences: {
        providesTags: ['BarcodeSequence'],
      },
      postBarcodeSequences: {
        invalidatesTags: invalidateTags(['BarcodeSequence']),
      },
      getCarriers: {
        providesTags: ['Carrier'],
      },
      postCarriers: {
        invalidatesTags: invalidateTags(['Carrier']),
      },
      patchCarriersByCarrierId: {
        invalidatesTags: invalidateTags(['Carrier']),
      },
      deleteCarriersByCarrierId: {
        invalidatesTags: invalidateTags(['Carrier']),
      },
    },
  })
  .injectEndpoints({
    endpoints: (build) => ({
      downloadLocationFile: build.mutation<string, GetLocationsDownloadApiArg>({
        query: (queryArgs) => ({
          url: '/locations/download',
          params: convertKeysToSnakeCase(queryArgs),
          responseHandler: (response) => response.text(),
        }),
      }),
      downloadBinLocationFile: build.mutation<string, GetBinLocationsDownloadApiArg>({
        query: (queryArgs) => ({
          url: '/bin-locations/download',
          params: convertKeysToSnakeCase(queryArgs),
          responseHandler: (response) => response.text(),
        }),
      }),
      downloadItemsFile: build.mutation<string, GetItemsDownloadApiArg>({
        query: (queryArgs) => ({
          url: '/items/download',
          params: convertKeysToSnakeCase(queryArgs),
          responseHandler: (response) => response.text(),
        }),
      }),
      downloadOutboundsFile: build.mutation<string, GetOutboundsDownloadApiArg>({
        query: (queryArgs) => ({
          url: '/outbounds/download',
          params: convertKeysToSnakeCase(queryArgs),
          responseHandler: (response) => response.text(),
        }),
      }),
      downloadProductsFile: build.mutation<string, GetProductsDownloadApiArg>({
        query: (queryArgs) => ({
          url: '/products/download',
          params: convertKeysToSnakeCase(queryArgs),
          responseHandler: (response) => response.text(),
        }),
      }),
      downloadInboundsFile: build.mutation<string, GetInboundsDownloadApiArg>({
        query: (queryArgs) => ({
          url: '/inbounds/download',
          params: convertKeysToSnakeCase(queryArgs),
          responseHandler: (response) => response.text(),
        }),
      }),
      downloadUsersFile: build.mutation<string, GetUsersDownloadApiArg>({
        query: (queryArgs) => ({
          url: '/users/download',
          params: convertKeysToSnakeCase(queryArgs),
          responseHandler: (response) => response.text(),
        }),
      }),
      downloadAdjustmentsForProductId: build.mutation<string, string>({
        query: (id: string) => ({
          url: `/products/${id}/adjustments/download`,
          responseHandler: (response) => response.text(),
        }),
      }),
      downloadAttachmentsByAttachmentId: build.mutation<string, string>({
        query: (attachmentId: string) => ({
          url: `/attachments/${attachmentId}`,
          responseHandler: (response) => response.blob(),
        }),
      }),
      downloadProductUomTemplate: build.mutation<string, string>({
        query: () => ({
          url: '/products/uom/template',
          responseHandler: (response) => response.blob(),
        }),
      }),
      getConfig: build.query<EnvConfig, void>({
        queryFn: async () => {
          try {
            const override = fetchOverrideConfig();
            const data = await fetchStaticConfig();
            return { data: { ...data, ...override } };
          } catch (err) {
            return {
              error: { status: 500, data: 'Error fetching config' },
            };
          }
        },
      }),
      getPackingSlip: build.query<
        { contentType?: string; data?: string },
        GetOutboundsByOutboundIdParcelsAndParcelIdPackingSlipApiArg
      >({
        query: (queryArg) => ({
          url: `/outbounds/${queryArg.outboundId}/parcels/${queryArg.parcelId}/packing-slip`,
          params: { format: queryArg.format },
          responseHandler: async (response) => {
            const contentType = response.headers.get('content-type');
            return { contentType, data: await response.text() };
          },
        }),
      }),
      uploadKitAttachment: build.mutation({
        invalidatesTags: invalidateTags(['Attachment']),
        query: ({
          kitId,
          attachment,
        }: PostKitsByKitIdAttachmentsApiArg & {
          attachment: Attachment & { file: File };
        }) => ({
          url: `/kits/${kitId}/attachments`,
          method: 'POST',
          body: Object.keys(attachment ?? {}).reduce((formData, attr) => {
            formData.append(attr, attachment[attr as keyof Attachment] as string | Blob);
            return formData;
          }, new FormData()),
        }),
      }),
      uploadOutboundAttachment: build.mutation({
        invalidatesTags: invalidateTags(['Attachment']),
        query: ({
          outboundId,
          attachment,
        }: PostOutboundsByOutboundIdAttachmentsApiArg & {
          attachment: Attachment & { file: File };
        }) => ({
          url: `/outbounds/${outboundId}/attachments`,
          method: 'POST',
          body: Object.keys(attachment ?? {}).reduce((formData, attr) => {
            formData.append(attr, attachment[attr as keyof Attachment] as string | Blob);
            return formData;
          }, new FormData()),
        }),
      }),
      uploadWorkOrderAttachment: build.mutation({
        invalidatesTags: invalidateTags(['Attachment']),
        query: ({
          workOrderId,
          attachment,
        }: PostWorkOrdersByWorkOrderIdAttachmentsApiArg & {
          attachment: Attachment & { file: File };
        }) => ({
          url: `/work-orders/${workOrderId}/attachments`,
          method: 'POST',
          body: Object.keys(attachment ?? {}).reduce((formData, attr) => {
            formData.append(attr, attachment[attr as keyof Attachment] as string | Blob);
            return formData;
          }, new FormData()),
        }),
      }),
      uploadBrandedPackingSlipLogo: build.mutation({
        invalidatesTags: invalidateTags(['Attachment', 'PackingSlipTemplate']),
        query: ({
          id,
          attachment,
        }: PostPackingSlipTemplatesByIdLogoApiArg & {
          attachment: Attachment & { file: File };
        }) => ({
          url: `/packing-slip-templates/${id}/logo`,
          method: 'POST',
          body: Object.keys(attachment ?? {}).reduce((formData, attr) => {
            formData.append(attr, attachment[attr as keyof Attachment] as string | Blob);
            return formData;
          }, new FormData()),
        }),
      }),
    }),
  });

export { api };

export const {
  // Activities
  useGetActivitiesQuery,
  useLazyGetActivitiesQuery,

  // Announcements
  useDeleteAnnouncementsByAnnouncementIdMutation,
  useGetAnnouncementsQuery,
  useGetAnnouncementsByAnnouncementIdQuery,
  usePostAnnouncementsMutation,
  usePatchAnnouncementsByAnnouncementIdMutation,
  useGetBuildingAnnouncementsQuery,
  usePostBuildingAnnouncementsMutation,
  usePostBuildingAnnouncementsBulkMutation,

  // Attachments
  useDeleteAttachmentsByAttachmentIdMutation,
  useDownloadAttachmentsByAttachmentIdMutation,
  useGetOutboundsByOutboundIdAttachmentsQuery,

  // Batches
  useGetBatchesByBatchIdQuery,
  useLazyGetBatchesByBatchIdQuery,
  usePatchBatchesByBatchIdMutation,
  useGetBatchesQuery,
  usePostBatchesByBatchIdPickMutation,
  useGetBatchesByBatchIdPickPathQuery,
  useLazyGetBatchesByBatchIdPickPathQuery,
  usePostBatchesByBatchIdAlternatePickMutation,
  useGetBatchesAssignedUsersQuery,
  usePostBatchesByBatchIdReleaseMutation,
  usePostBatchesByBatchIdShortSplitMutation,
  useDeletePickByPickIdMutation,
  usePatchPickByPickIdMutation,
  useGetPicksQuery,

  // Broadcasts
  usePostBroadcastsMutation,

  // Buildings
  useGetBuildingsQuery,
  useLazyGetBuildingsQuery,
  useGetBuildingsByBuildingIdQuery,
  useLazyGetBuildingsByBuildingIdQuery,
  usePatchBuildingsByBuildingIdMutation,
  usePostBuildingsMutation,

  //Cycle Counts
  useGetCycleCountsQuery,
  useGetCycleCountsByCycleCountIdQuery,
  usePatchCycleCountsByCycleCountIdMutation,
  usePostCycleCountsByCycleCountIdLocationsAndCycleCountLocationIdResultsMutation,
  usePatchCycleCountsByCycleCountIdLocationsAndCycleCountLocationIdResultsCycleCountResultIdMutation,
  useDeleteCycleCountsByCycleCountIdLocationsAndCycleCountLocationIdResultsCycleCountResultIdMutation,
  usePostCycleCountsBulkMutation,
  usePatchCycleCountsAssignMutation,

  // Exceptions
  useGetOutboundExceptionsByIdQuery,
  useGetOutboundExceptionsQuery,
  useLazyGetOutboundExceptionsQuery,
  usePostOutboundExceptionsMutation,
  usePostCycleCountExceptionsMutation,
  usePostInboundExceptionsMutation,

  // Inbound Orders
  useGetInboundsQuery,
  useLazyGetInboundsQuery,
  useGetShippersByShipperNameInboundsAndOrderNumberQuery,
  useLazyGetInboundsByInboundOrderIdQuery,
  useLazyGetShippersByShipperNameInboundsAndOrderNumberQuery,
  usePatchInboundsByInboundOrderIdMutation,
  useGetInboundsByInboundOrderIdLinesQuery,
  useGetInboundsByInboundOrderIdItemsQuery,
  useDownloadInboundsFileMutation,
  usePostInboundsMutation,

  // Items
  useGetItemsQuery,
  useLazyGetItemsQuery,
  useGetItemsByItemIdQuery,
  usePatchItemsByItemIdMutation,
  usePostItemsMutation,
  useLazyGetItemsByItemIdQuery,
  usePostItemsByItemIdAdjustmentsMutation,
  useDownloadItemsFileMutation,
  useDownloadAdjustmentsForProductIdMutation,
  usePostItemsUploadMutation,
  useGetItemsLotNumbersQuery,

  // License Plates
  useGetLicensePlatesQuery,
  useLazyGetLicensePlatesQuery,
  useGetLicensePlatesByLicensePlateIdQuery,
  useLazyGetLicensePlatesByLicensePlateIdQuery,
  useGetLicensePlatesNumberByLicensePlateNumberQuery,
  useLazyGetLicensePlatesNumberByLicensePlateNumberQuery,
  usePatchLicensePlatesByLicensePlateIdMutation,
  usePutLicensePlatesByLicensePlateIdMoveMutation,
  usePostLicensePlatesByLicensePlateIdSplitMutation,
  usePostLicensePlatesByLicensePlateIdReplaceMutation,
  usePostLicensePlatesByLicensePlateIdTransferMutation,
  usePostLicensePlatesLockMutation,
  usePostLicensePlatesUnlockMutation,

  useGetMovementsQuery,

  // Locations
  useGetLocationsQuery,
  useLazyGetLocationsQuery,
  useGetLocationsByLocationIdQuery,
  useLazyGetLocationsByLocationIdQuery,
  useDownloadLocationFileMutation,
  useDownloadBinLocationFileMutation,
  usePatchLocationsByLocationIdMutation,
  usePatchLocationsBulkAssignZoneMutation,
  usePostLocationsUploadMutation,
  useGetLocationsByLocationIdAlternateQuery,
  useLazyGetLocationsByLocationIdAlternateQuery,
  useGetLocationsPickingQuery,
  useGetLocationsEmptyPutawayLocationsQuery,
  useLazyGetLocationsEmptyPutawayLocationsQuery,

  // Locations
  useGetBinLocationsQuery,

  // Mark as Shipped
  useGetOutboundsQuery,
  useLazyGetOutboundsQuery,
  usePostOutboundsShipMutation,

  // Mark as Packed
  usePatchPackoutOutboundsByOutboundIdPackedMutation,

  // Outbound Orders
  useGetOutboundsByOrderIdQuery,
  useDownloadOutboundsFileMutation,
  useGetShippersByShipperNameOutboundsAndOrderNumberQuery,
  usePatchOutboundsByOrderIdMutation,
  usePostOutboundsUnbatchMutation,
  useGetOutboundsByOrderIdLinesQuery,
  useGetOutboundsLineItemsQuery,
  useGetOutboundsByOrderIdLineItemsQuery,
  useLazyGetOutboundsByOrderIdLineItemsQuery,
  useLazyGetOutboundsByOrderIdLinesQuery,
  usePostOutboundsByOrderIdLinesAndOutboundLineIdPickMutation,
  usePostOutboundsStageMutation,
  usePostOutboundsUnpickMutation,
  usePostOutboundsByOrderIdRepublishMutation,
  usePostBatchesBulkMutation,

  // Channel names
  useGetOutboundsChannelNamesQuery,

  // Shipping Carriers
  useGetOutboundsShippingCarriersQuery,

  // Tags
  useGetOutboundsTagsQuery,

  // Outbound Shipments
  usePostOutboundsByOrderIdShipmentsMutation,
  usePatchOutboundsByOrderIdShipmentsAndShipmentIdMutation,
  useGetOutboundsByOutboundIdParcelsQuery,
  usePostOutboundsByOutboundIdParcelsMutation,
  usePostOutboundsByOutboundIdParcelsAndParcelIdGenerateLabelMutation,
  useDeleteOutboundsByOutboundIdParcelsAndParcelIdMutation,
  useDeleteOutboundsByOutboundIdParcelsInProgressMutation,
  useGetParcelsQuery,
  useLazyGetParcelsQuery,
  useGetParcelsByParcelIdQuery,
  usePostParcelsByParcelIdShipMutation,

  // Overview
  useGetOverviewInboundQuery,
  useGetOverviewOutboundQuery,

  // Pick Equipment Groups
  useGetPickEquipmentGroupsQuery,
  usePostPickEquipmentGroupsMutation,
  usePatchPickEquipmentGroupsByPickEquipmentGroupIdMutation,
  useDeletePickEquipmentGroupsByPickEquipmentGroupIdMutation,

  // Printers
  useGetPrintersQuery,
  useDeletePrintersByPrinterIdMutation,
  usePatchPrintersByPrinterIdMutation,
  usePostPrintersMutation,

  // Products
  useGetProductsQuery,
  useLazyGetProductsQuery,
  useGetProductsByProductIdQuery,
  usePostProductsMutation,
  useDeleteProductsByProductIdMutation,
  useDownloadProductsFileMutation,
  usePostProductsUploadMutation,
  useGetShippersByShipperNameProductsAndSkuLocationSummaryQuery,
  useGetShippersByShipperNameProductsAndSkuQuery,
  useLazyGetProductsByProductIdQuery,
  usePatchShippersByShipperNameProductsAndSkuMutation,

  // Product Barcodes
  useDeleteProductsByProductIdBarcodesAndProductBarcodeIdMutation,
  usePostProductsByProductIdBarcodesMutation,
  usePatchProductsByProductIdBarcodesAndProductBarcodeIdMutation,

  // Product Lot Numbers
  useGetProductLotNumbersQuery,
  usePatchShippersByShipperNameProductsAndSkuLotNumbersLotNumberMutation,
  usePostShippersByShipperNameProductsAndSkuLotNumbersMutation,

  // Providers
  useGetProvidersQuery,

  // Receipts
  useGetInboundsByInboundOrderIdReceiptsQuery,
  useLazyGetInboundsByInboundOrderIdReceiptsQuery,
  usePatchInboundsByInboundOrderIdReceiptsAndReceiptIdMutation,
  useGetInboundsReceiptsQuery,
  usePostInboundsByInboundOrderIdRepublishReceiptsMutation,

  // Receipt Lines
  useGetReceiptsByReceiptIdReceiptLinesQuery,

  // Scanner Inbound Receiving
  useGetInboundsByInboundOrderIdLinesAndInboundLineIdQuery,
  usePatchInboundsByInboundOrderIdLinesAndInboundLineIdMutation,
  usePostBatchesMutation,
  usePatchInboundsByInboundOrderIdLinesAndInboundLineIdLpnsLicensePlateIdItemsItemIdMutation,
  useDeleteInboundsByInboundOrderIdLinesAndInboundLineIdLpnsLicensePlateIdItemsItemIdMutation,
  usePostInboundsByInboundOrderIdLinesAndInboundLineIdLpnsMutation,

  // Serial Numbers
  useGetSerialNumbersQuery,
  useGetSerialNumbersBySerialNumberIdQuery,

  // Shippers
  useGetShippersAllQuery,
  useGetShippersQuery,
  useGetShippersByShipperIdQuery,
  useGetShippersByShipperIdAddressesQuery,
  usePatchShippersByShipperIdMutation,
  usePatchShippersByShipperIdAddressesAndShipperAddressIdMutation,
  usePostShippersMutation,
  usePostShippersByShipperIdAddressesMutation,
  useDeleteShippersByShipperIdAddressesAndShipperAddressIdMutation,
  usePostShippersByShipperIdObfuscateMutation,
  usePostShippersByShipperIdPurgeInventoryMutation,

  // SpecialInstructions
  useDeleteShippersByShipperIdSpecialInstructionsAndSpecialInstructionIdMutation,
  useGetShippersByShipperIdSpecialInstructionsQuery,
  usePatchShippersByShipperIdSpecialInstructionsAndSpecialInstructionIdMutation,
  usePostShippersByShipperIdSpecialInstructionsMutation,

  // Users
  useDeleteUsersByUserIdBlockMutation,
  useDownloadUsersFileMutation,
  useGetUsersQuery,
  useGetUsersByUserIdQuery,
  useGetMeQuery,
  useLazyGetUsersByUserIdQuery,
  usePatchUsersByUserIdMutation,
  usePostUsersMutation,
  usePatchMeMutation,

  // UoM
  useGetUomQuery,
  usePostUomMutation,
  useDeleteUomByUomIdMutation,
  useDeleteProductsByProductIdUomAndProductUomIdMutation,
  usePatchProductsByProductIdUomAndProductUomIdMutation,
  usePostProductsByProductIdUomMutation,
  usePostProductsUomUploadMutation,
  useDownloadProductUomTemplateMutation,

  // Timezones
  useGetTimezonesQuery,

  // manual defined endpoints
  useGetConfigQuery,

  // Attachments
  useUploadOutboundAttachmentMutation,

  // Shipper config
  useGetShipperConfigsQuery,
  useGetShippersByShipperIdConfigQuery,
  useLazyGetShippersByShipperIdConfigQuery,
  usePatchShippersByShipperIdConfigMutation,
  usePatchShippersByShipperIdConfigPremiumFeaturesMutation,
  useGetAutomationConfigsQuery,
  useLazyGetAutomationConfigsQuery,
  useGetAutomationConfigsByAutomationConfigIdQuery,
  usePostAutomationConfigsMutation,
  usePatchAutomationConfigsByAutomationConfigIdMutation,
  useDeleteAutomationConfigsByAutomationConfigIdMutation,
  usePostAutomationConfigsByAutomationConfigIdRestreamMutation,
  usePostAutomationConfigsTriggerMutation,
  useGetAutomationConfigsPutawayLpnByLicensePlateIdQuery,
  useLazyGetAutomationConfigsPutawayLpnByLicensePlateIdQuery,

  // Barcodes
  usePostGeneratedBarcodesBulkMutation,
  useGetBarcodeSequencesQuery,
  usePostBarcodeSequencesMutation,

  // Packaging
  useGetShippingPackagingQuery,
  useDeleteShippingPackagingByPackagingIdMutation,
  usePatchShippingPackagingByPackagingIdMutation,
  usePostShippingPackagingMutation,
  useGetShippingPackagingByPackagingIdQuery,

  // Adjustments
  useGetAdjustmentsReasonCodesQuery,
  useGetAdjustmentsQuery,

  // Work Orders and Kits
  useGetWorkOrdersQuery,
  usePostWorkOrdersMutation,
  usePostWorkOrdersByWorkOrderIdPickMutation,
  usePostWorkOrdersByWorkOrderIdReplenishMutation,
  usePostWorkOrdersStageReplenishmentsMutation,
  usePostWorkOrdersByWorkOrderIdStageMutation,
  usePatchWorkOrdersByWorkOrderIdMutation,
  usePatchWorkOrderStepsByWorkOrderStepIdAssignMutation,
  useGetKitsByKitIdQuery,
  usePostKitsMutation,
  usePatchKitsByKitIdMutation,
  useGetShippersByShipperNameWorkOrdersAndNumberQuery,
  useLazyGetWorkOrdersQuery,
  useGetWorkOrdersByWorkOrderIdQuery,
  useLazyGetWorkOrdersByWorkOrderIdQuery,
  useGetKitsByKitIdAttachmentsQuery,
  useUploadKitAttachmentMutation,
  usePostWorkOrdersByWorkOrderIdAssembleKitMutation,
  usePostWorkOrdersByWorkOrderIdMoveMutation,
  useGetWorkOrdersByWorkOrderIdAttachmentsQuery,
  useUploadWorkOrderAttachmentMutation,
  usePatchWorkOrdersAssignMutation,
  useGetWorkOrdersReplenishmentLookAheadQuery,

  // S1C Mappings
  useGetOrionWarehouseMappingsQuery,
  usePostOrionWarehouseMappingsMutation,
  usePatchOrionWarehouseMappingsByOrionWarehouseMappingIdMutation,

  // Tracking
  useGetTrackingQuery,

  // barcode lookup
  useLazyGetBarcodeLookupQuery,

  // Test Data Routes
  usePostAdminBulkPackedStreamMutation,

  // Insights
  usePostInsightsDomoUrlMutation,

  // Bulk ship orders
  usePostShippersByShipperIdOutboundsShipMutation,

  // Packing slips
  useLazyGetPackingSlipQuery,
  useGetPackingSlipTemplatesQuery,
  useGetPackingSlipTemplatesByIdQuery,
  usePatchPackingSlipTemplatesByIdMutation,
  usePostPackingSlipTemplatesMutation,
  usePostPackingSlipTemplatesByPackingSlipTemplateIdChannelsMutation,
  useDeletePackingSlipTemplatesByPackingSlipTemplateIdChannelsAndIdMutation,
  useDeletePackingSlipTemplatesByIdMutation,
  useDeletePackingSlipTemplatesByIdLogoMutation,
  useUploadBrandedPackingSlipLogoMutation,

  // Location Zones
  useGetZonesQuery,
  useGetZonesByZoneIdQuery,
  usePostZonesMutation,
  usePatchZonesByZoneIdMutation,
  useDeleteZonesByZoneIdMutation,
  usePostZonesLockMutation,
  usePostZonesUnlockMutation,

  // Carriers
  useDeleteCarriersByCarrierIdMutation,
  useGetCarriersQuery,
  usePatchCarriersByCarrierIdMutation,
  usePostCarriersMutation,

  usePrefetch,
} = api;

export type EnvConfigKeys =
  | 'API_URL'
  | 'AUTH0_DOMAIN'
  | 'AUTH0_CLIENT_ID'
  | 'DATADOG_APPLICATION_ID'
  | 'DATADOG_CLIENT_TOKEN'
  | 'DATADOG_SERVICE_NAME'
  | 'DATADOG_ENV'
  | 'LAUNCHDARKLY_CLIENT_ID'
  | 'NOVU_APP_ID'
  | 'USERFLOW_TOKEN'
  | 'DOMO_DOMAIN';

export type EnvConfig = Record<EnvConfigKeys, string>;
